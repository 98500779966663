import { Fields } from '~/types/Fields.model'

export const fields: Fields[] = [
  {
    id: 'lineNumber',
    label: 'Line',
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: 'Item',
    minWidth: 80,
  },
  {
    id: 'quantity',
    label: 'Qty',
    minWidth: 50,
  },
  {
    id: 'reason',
    label: 'Return Reason Code',
    minWidth: 100,
  },
]
