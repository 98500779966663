import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { useAccountMetadata } from '~/services/accounts'
import {
  exportProductFile,
  fetchFutureProducts,
  fetchProducts,
} from '~/services/priceList'
import { Account, SubAccount } from '~/types/Account.model'
import { TabsType } from '~/types/TabsType.model'
import NoDataFoundModal from '~/UI/modals/NoDataFoundModal'
import { TEXTS } from '~/utils/allConstants/Constants'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import ContentWrapper from '../UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '../UI/DataGrid/DataGridContext'
import Loading from '../UI/Loading'
import TabsTablePage from '../UI/TabsTablePage'

import { fields as currentFields } from './components/CurrentPriceListTabContent'
import { fields as futureFields } from './components/FuturePriceListTabContent'

const {
  CURRENT_PRICE_LIST,
  FUTURE_PRICE_LIST,
  NO_DATA_MESSAGE,
  ACCOUNT_SELECT_MESSAGE,
  EXPORT_AS_PDF,
} = TEXTS

const PriceList = () => {
  const { active_tab } = useParams()
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  const {
    setTableProps,
    tableProps,
    sort,
    filters: storeFilters,
  } = useDataGridStore()

  const { data: accountMetadata } = useAccountMetadata()

  const filters = useMemo(() => {
    const f = {} as { accountId: string }
    if (selectedAccount) {
      f.accountId = selectedAccount.id

      if (selectedSubAccount) {
        f.accountId = selectedSubAccount.id
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const { data, isLoading } = useQuery(
    [
      `future_price`,
      {
        filters,
        page: 1,
        pageSize: 100,
      },
    ],
    () => {
      return (
        selectedAccount &&
        fetchFutureProducts({
          ...filters,
          fields: futureFields,
          page: 1,
          pageSize: 100,
        })
      )
    },
    {
      keepPreviousData:
        selectedSubAccount?.customerNumber === selectedAccount?.customerNumber,
      enabled: selectedSubAccount != undefined,
      placeholderData: {
        items: [],
        totalCount: 0,
      },
    }
  )

  const currentTab = data?.totalCount > 0 ? active_tab || 'current' : 'current'
  useSynchAccountsNavigation(`price-list/${currentTab}`)

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilters: {
          ...filters,
        },
      }
    })
  }, [filters])

  const handleExcelExport = useCallback(() => {
    ReactGA.event({
      category: 'Products',
      action: 'Export as Excel',
    })
    return exportProductFile({
      extension: 'xlsx',
      tab: currentTab,
      filters: storeFilters,
      ...tableProps.additionalFilters,
      sort: sort !== '' ? sort : undefined,
      fields: tableProps.fields,
    })
  }, [tableProps, storeFilters, sort, currentTab])

  const handlePdfExport = useCallback(() => {
    ReactGA.event({
      category: 'Products',
      action: EXPORT_AS_PDF,
    })
    return exportProductFile({
      extension: 'pdf',
      tab: currentTab,
      filters: storeFilters,
      ...tableProps.additionalFilters,
      sort: sort !== '' ? sort : undefined,
      fields: tableProps.fields,
    })
  }, [tableProps, storeFilters, sort, currentTab])

  const defaultProps = {
    additionalFilters: filters,
    initialPageSize: 100,
    pageSizeOptions: [
      { value: 100, label: '100' },
      { value: 500, label: '500' },
      { value: 'all', label: '1000+' }, // custom `all` pageSize value, supported by BE for Product API
    ],
    notFoundMessage: NO_DATA_MESSAGE('Products'),
  }

  const params: TabsType[] = [
    {
      label: CURRENT_PRICE_LIST,
      name: 'current_price',
      source: fetchProducts,
      ...defaultProps,
      fields: currentFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
      },
    },
    {
      label: FUTURE_PRICE_LIST,
      name: 'future_price',
      source: fetchFutureProducts,
      ...defaultProps,
      fields: futureFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
      },
    },
  ]

  if (
    !selectedAccount ||
    !selectedSubAccount ||
    (selectedSubAccount && accountMetadata?.priceSetupAt !== 'ACCOUNT')
  ) {
    if (!selectedAccount || !selectedSubAccount) {
      return <NoDataFoundModal message={ACCOUNT_SELECT_MESSAGE} />
    }
  }

  if (isLoading) {
    return <Loading />
  }

  const updatedParams = data?.totalCount > 0 ? params : params.slice(0, -1)

  return (
    <ContentWrapper>
      <TabsTablePage
        key={currentTab}
        currentTab={currentTab}
        tabs={updatedParams}
        handleExcelExport={handleExcelExport}
        handlePdfExport={handlePdfExport}
      />
    </ContentWrapper>
  )
}

// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider initialPageSize={100}>
    <PriceList />
  </DataGridProvider>
))
