import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import '../../components/ReturnRequest.scss'

const { LINE, ITEM, QTY, RETURN_REASON_CODE } = TEXTS
export const fields: Fields[] = [
  {
    id: 'lineNumber',
    label: LINE,
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM,
    minWidth: 80,
  },
  {
    id: 'quantity',
    label: QTY,
    minWidth: 50,
    format: (_, row) => {
      return row.quantity
    },
  },
  {
    id: 'reason',
    label: RETURN_REASON_CODE,
    minWidth: 100,
  },
]
