import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const { ITEM_NO, DESCRIPTION, AVAILABLE_RETURN_QTY, SHIPPED_QTY } = TEXTS

export const fields: Fields[] = [
  { id: 'lineNumber', label: 'Line', minWidth: 30 },
  {
    id: 'itemNo',
    label: ITEM_NO,
    minWidth: 80,
  },
  {
    id: 'description',
    label: DESCRIPTION,
    minWidth: 200,
  },
  {
    id: 'unitOfMeasure',
    label: 'U/M',
    minWidth: 50,
  },
  {
    id: 'shippedCount',
    label: SHIPPED_QTY,
    minWidth: 50,
  },
  {
    id: 'availableReturnQuantity',
    label: AVAILABLE_RETURN_QTY,
    minWidth: 100,
  },
]
