import { Chip, Typography } from '@mui/material'

import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { toTitleCase } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorYellowishWhite, acrColorYellow } = variables
const {
  LINE,
  ITEM_NO,
  QTY,
  RETURN_REASON_CODE,
  APPROVAL_STATUS,
  DENIAL_REASON_CODE,
} = TEXTS

export const fields: Fields[] = [
  {
    id: 'lineNumber',
    label: LINE,
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM_NO,
    minWidth: 150,
  },
  {
    id: 'quantity',
    label: QTY,
    minWidth: 150,
  },
  {
    id: 'reason',
    label: RETURN_REASON_CODE,
    minWidth: 100,
  },
  {
    id: 'approved',
    label: APPROVAL_STATUS,
    minWidth: 50,
    format: (_, row) => {
      if (row.approved === false) {
        return (
          <Chip
            label={'Denied'}
            sx={{
              backgroundColor: acrColorYellowishWhite,
              border: '1px solid',
              borderColor: acrColorYellow,
            }}
          />
        )
      } else if (row.approved === true) {
        return (
          <Chip
            label={'Approved'}
            sx={{
              backgroundColor: acrColorYellowishWhite,
              border: '1px solid',
              borderColor: acrColorYellow,
            }}
          />
        )
      } else {
        return <Typography>N/A</Typography>
      }
    },
    sort: true,
  },
  {
    id: 'itemDenialReasonList',
    label: DENIAL_REASON_CODE,
    minWidth: 80,
    format: (_, row) => {
      if (row.itemDenialReasonList === null || row.approved === null) {
        return <Typography>N/A</Typography>
      } else {
        return toTitleCase(
          row.itemDenialReasonList.toString().replace(/,/g, ', ')
        ).replaceAll('_', ' ')
      }
    },
  },
]
