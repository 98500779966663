import { Chip } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Fields } from '~/types/Fields.model'
import { Order } from '~/types/Order.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { ORDER_STATUSES_CLOSED_ORDERS } from '~/utils/allConstants/HardcodedFilterDropdownValues'
import { formatCurrency, formatDate } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorYellowishWhite, acrColorYellow } = variables
const {
  ORDER_NUMBER,
  ORDER_TOTAL,
  PURCHASE_ORDER,
  ORDER_DATE,
  REQ_DELIVERY_DATE,
  ACTUAL_SHIPMENT_DATE,
  WHO_PLACED_ORDER,
  CARRIER,
  TRACKING_NUMBER,
  ORDER_STATUS,
  INVOICED,
  CLOSED,
} = TEXTS

const fields: Fields[] = [
  {
    id: 'orderNumber',
    label: ORDER_NUMBER,
    minWidth: 110,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (value: string) => (
      <InternalLink to={`/orders/${value}/details`}>{value}</InternalLink>
    ),
    sort: true,
  },
  {
    id: 'orderTotal',
    label: ORDER_TOTAL,
    minWidth: 90,
    filterFieldConfiguration: {
      type: 'range-freeText',
      prefix: '$',
    },
    format: (value: number) => formatCurrency(value),
    sort: true,
  },
  {
    id: 'purchaseOrder',
    label: PURCHASE_ORDER,
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
  },
  {
    id: 'orderStatus',
    label: ORDER_STATUS,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'select',
      values: ORDER_STATUSES_CLOSED_ORDERS,
    },
    format: (_, row: Order) =>
      row.orderStatus ? (
        <Chip
          label={row.orderStatus === CLOSED ? INVOICED : row.orderStatus}
          sx={{
            backgroundColor: acrColorYellowishWhite,
            border: '1px solid',
            borderColor: acrColorYellow,
          }}
        />
      ) : null,
    sort: false,
  },
  {
    id: 'orderDate',
    label: ORDER_DATE,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'reqDeliveryDate',
    label: REQ_DELIVERY_DATE,
    minWidth: 140,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'actualShipmentDate',
    label: ACTUAL_SHIPMENT_DATE,
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'orderPlacedBy',
    label: WHO_PLACED_ORDER,
    minWidth: 80,
    sort: true,
  },
  {
    id: 'shipper',
    label: CARRIER,
    minWidth: 80,
    sort: true,
  },
  {
    id: 'trackingNumber',
    label: TRACKING_NUMBER,
    minWidth: 130,
    format: (value: string, row: Order) =>
      row.trackingUrl ? (
        <InternalLink to={row.trackingUrl}>{value}</InternalLink>
      ) : (
        value
      ),
    sort: true,
  },
]

export { fields }
